.btn-consultar-card {
    font-family: "HyundaiSansHead-Medium";
    width: 100%;
    background: #002C5F;
    box-shadow: 0px 4.71681px 4.71681px RGB(47 55 60 / 14%);
    border: none;
    color: white;
    padding: 10px 0px;
    font-weight: 450;
    font-size: 20px;
    line-height: 26px;
    margin-top:20px;
    transition: all 0.5s ease-out;
    
}
.btn-consultar-card:hover {
    background: #00244D;
}
.card-new-vehicle {
    background: #FFFFFF;
    border: 1.1792px solid rgba(0, 0, 0, 0.08);
    border-radius: 9.43363px;
    padding: 30px;
    filter: drop-shadow(0px 3.53761px 9.43363px rgba(0, 0, 0, 0.08));
    .card-new-vehicle-header {
        margin-bottom: 24px;
        .titulo-tarjeta {
            font-family: "HyundaiSansHead-Bold";
            color: #2B2B2B;
            font-weight: 600;
            font-size: 32px;
            line-height: 41px;
            text-transform: uppercase;
            margin-bottom: 0px;
        }
        .titulo-version-tarjeta {
            font-family: "HyundaiSansHead-Medium";
            color: #8C8C8C;
            font-weight: 450;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }
    .card-new-vehicle-imagen {
        margin-bottom: 20px;
        text-align: center;
    }
    .card-new-vehicle-footer {
        min-height: 160px;
        @media only screen and (max-width: 790px) {
            min-height: auto;
        }
        .vehicle-footer-entrega-inmediata {
            text-align: center;
            margin-bottom: 25px;
            p {
                font-family: "HyundaiSansHead-Medium";
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 5px;
                margin-bottom: 0px;
                color: #616161;
                font-weight: 450;
                font-size: 14px;
            }
        }
        .vehicle-footer-descripcion-general {
            text-align: center; 
            p {
                color: #323232;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 0px;
            }
        }
        .vehicle-footer-subtitulo-principal {
            text-align: center;
            margin-bottom: 10px;
            p {
                font-weight: 700;
                font-size: 24px;
                color: #323232;
                margin-bottom: 0px;
            }
        }
        .vehicle-footer-descripcion-subtitulo-principal {
            text-align: center;
            p {
                font-weight: 400;
                font-size: 16px;
                color: #323232;
                margin-bottom: 0;
            }
        }
        .vehicle-footer-cuota-desde {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .primer-parrafo {
                color: #8C8C8C;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
            }
            .segundo-parrafo {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0;
                color: #333333;
            }
        }
        .vehicle-footer-planes-cuotas {
            .vehicle-footer-planes-cuotas-card {
                margin-bottom: 25px;
                .vehicle-footer-planes-cuotas-card-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding-bottom: 6px;
                    border-bottom: solid #C4C4C4;
                    margin-bottom: 8px;
                    .cuotas-card-header-titulo {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 19px;
                        color: #A21546;
                    }
                    .cuotas-card-header-cuotas-desde {
                        .cuotas-card-header-cuotas-desde-nombre {
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #8C8C8C;
                        }
                        .cuotas-card-header-cuotas-desde-precio {
                            margin-bottom: 0;
                            color: #333333;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 31px;
                            text-align: right;
                        }
                    }
                }
                .vehicle-footer-planes-cuotas-card-footer {
                    text-align: center;
                    p {
                        color: #8C8C8C;
                        font-weight: 400;
                        font-size: 12px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .vehicle-footer-titulo-principal {
            text-align: left;
            .vehicle-footer-titulo-principal-a {
                font-family: "HyundaiSansHead-Bold";
                text-align: left;
                color: #323232;
                font-weight: 600;
                font-size: 22px;
                margin-bottom: 0;
                text-transform: uppercase;
                line-height: 100%;
                margin-bottom: 10px;
                max-width: 300px;
                margin: 0;
                margin-bottom: 10px;
                @media only screen and (max-width: 470px) {
                    max-width: initial;
                }
            }
            .vehicle-footer-titulo-principal-b {
                font-family: "HyundaiSansHead-Light";
                font-weight: 400;
                font-size: 18px;
                color: #323232;
                margin-bottom: 0;
                margin-bottom: 10px;
                min-width: max-content;
                @media only screen and (max-width: 470px) {
                    min-width: auto;
                }
            }
            .vehicle-footer-titulo-principal-c {
                font-weight: 450;
                font-size: 14px;
                color: #A21546;
                margin-bottom: 0;
            }
        }
    }
    
}