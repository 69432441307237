@font-face {
  font-family: "FuturaPTBold";
  src: url("../../assets/fonts/hyundai/HyundaiSansHead-Bold.otf");
}
@font-face {
  font-family: "FuturaPTLight";
  src: url("../../assets/fonts/hyundai/HyundaiSansHead-Light.otf");
}
@font-face {
  font-family: "FuturaPTMedium";
  src: url("../../assets/fonts/hyundai/HyundaiSansHead-Medium.otf");
}

// Hyundai
@font-face {
  font-family: "HyundaiSansHead-Bold";
  src: url("../../assets/fonts/hyundai/HyundaiSansHead-Bold.otf");
}
@font-face {
  font-family: "HyundaiSansHead-Light";
  src: url("../../assets/fonts/hyundai/HyundaiSansHead-Light.otf");
}
@font-face {
  font-family: "HyundaiSansHead-Medium";
  src: url("../../assets/fonts/hyundai/HyundaiSansHead-Medium.otf");
}