.footer {
  background-color: #002C5F;
  padding: 35px 15%;

  .legal {
    font-family: "HyundaiSansHead-Light";
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    color: $monti-white;
    margin-top: 42px;
    margin-bottom: 14px;
  }

  .social {
    color: $monti-white;
    font-size: 24px;

    p {
      font-size: 18px;
      line-height: 25px;
      color: $monti-white;
      margin-bottom: 0;
    }

    a {
      color: $monti-white;
    }

    a:hover {
      color: $monti-white;
    }
  }
}

.copyright {
  background-color: #FFFFFF;
  font-size: 11px;
  padding-top: 10px;
  margin-bottom: 0;
  p {
    font-family: "HyundaiSansHead-Light";
  }
}
.mostrar-mobile-legal {
  display: none !important;
}
.contenedorTectoCentradoMoble {
  width: 100%;
    text-align: center;
}
.contenedorRedesFooter {
  display: flex;
    justify-content: center;
    color: white;
    p {
      font-family: "HyundaiSansHead-Light";
    }
}
@media only screen and (max-width: 470px) {
  .footer {
    padding: 35px 35px;
  }
  .mostrar-mobile-legal {
    display: block !important;
  }
  .columnas-foots-centrar-mobile {
    flex-direction: column;
    gap: 1rem !important;
  }
  .ocultar-mobile-legal {
    display: none;
  }
  .footer .legal {
    font-size: 14px !important;
    line-height: 18px;
    
      br {
        display: none;
      }
  }
  .columna-seguinos-en {
    justify-content: space-around;
  }
  .contenedorRedesFooter {
    margin-top: 45px;
  }
}
