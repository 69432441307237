.modal-dialog {
    max-width: 661px;
}
#contained-modal-title-vcenter {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
}
.modal-content {
    box-shadow: 0px 2.55172px 2.55172px RGB(0 0 0 / 25%);
    border-radius: 25px;
    border: none;
}
.modal-header {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 15px 55px;
}
.modal-body {
    padding: 20px 25px;
    .titulo-formulario {
        font-weight: 400;
        font-size: 17px;
        line-height: 137%;
        background-image: url("../../assets/check-circle.png");
        background-repeat: no-repeat;
        background-position: left 2px;
        padding-left: 37px;
        margin-bottom: 20px;
    }
}
.btn-enviar-formulario {
    background: #002C5F;
    border: none;
    margin-top: 41px;
}
.btn-enviar-formulario:hover {
    background: #00244D;
}
.contenedor-form-nrocelular {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .contenedor-form-nrocelular-bandera {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.label-formulario-new {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 6px;
    margin-left: 15px;
}
.label-formulario-new-select {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.label-formulario-new-phone {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 15px;
}
.input-new-formulario {
    font-family: "FuturaPTLight" !important;
    border: 2px solid #D7D7D7;
    border-radius: 5px;
    padding: 19px 16px;
    color: #7E7E7E;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    gap: 10px;
}
#vehicle.input-new-formulario {
    font-family: "FuturaPTLight" !important;
    box-shadow: 0px 2px 8px RGB(0 0 0 / 25%);
    border: none;
    margin-top: 5px;
}
.row-new-formulario {
    margin-bottom: 25px;
}
.modalConfirmarCerrar {
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.3));
    background: #FFFFFF;
    border-radius: 15px;
    width: 480px;
    height: 262px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 49px;
    .modalConfirmarCerrar-parrafo {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        color: #333333;
    }
    .modalConfirmarCerrar-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .modalConfirmarCerrar-buttons-a {
            background: #FF1430;
            box-shadow: 0px 4.71681px 4.71681px rgba(47, 55, 60, 0.14);
            padding: 11px 49px;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: white;
            border: none;
        }
        .modalConfirmarCerrar-buttons-b {
            background: #C4C4C4;
            box-shadow: 0px 4.71681px 4.71681px rgba(47, 55, 60, 0.14);
            padding: 11px 49px;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: white;
            border: none;
        }
    }
}
.texto-loading-consulta {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #8C8C8C;
}
.titulo-formulario-enviado {
    text-align: left;
    font-family: "FuturaPTBold";
    font-size: 24px;
    line-height: 31px;
    color: #002C5F;
}
.subtitulo-formulario-enviado {
    text-align: left;
    font-family: "FuturaPTLight";
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}
.subtitulo-formulario-enviado-dos {
    text-align: left;
    font-family: "FuturaPTLight";
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}
.parrafo-formulario-enviado {
    font-family: "FuturaPTLight";
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}
.label-formulario-new-horario {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 21px;
    font-family: "FuturaPTLight";
}
.form-checks-formulario {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

@media only screen and (max-width: 470px) {
    .modal.show .modal-dialog {
        margin: 0;
    }
    .modal-content {
        border-radius: 0;
        min-height: 100vh;
    }
    .modalContacto .modal-header {
        border-radius: 0;
    }
    .modal-header {
        padding: 15px 20px;
    }
    .espacio-inpust-mobile {
        margin-bottom: 20px;
    }
    .label-formulario-new-phone {
        margin-bottom: 20px;
        margin-top: 5px;
        margin-left: 15px;
    }
    .label-formulario-new-select {
        margin-left: 15px;
    }
    .modalConfirmarCerrar {
        width: 90%;
        position: fixed;
    }
    .modalConfirmarCerrar-buttons {
        flex-direction: column;
    }
}