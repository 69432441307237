

body {
  font-family: "FuturaPTBold";
}

.btn-blue {
  background-color: $monti-blue;
  color: white;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  border-color: $monti-blue;
  border-radius: 0px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &.active {
    background-color:$monti-blue-light;

    border-color:$monti-blue-light;
    color:white;
  }
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
  background-color:$monti-blue-light;

  border-color:$monti-blue-light;
  color:white;
  font-weight: bold;
}

.btn-link {
  background-color: transparent !important;
  color: $monti-blue-light;
  font-weight: bold;
  padding: 10px;
  text-decoration: none;
  outline: none !important ;
  box-shadow: none !important;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: $monti-blue;
  font-weight: bold;
  outline: none !important;
  box-shadow: none !important;
}

.btnSpecsChanger {
  background-color: #e5e5e5;
  color: $monti-black;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 16px;
  text-decoration: none;
  outline: none !important ;
  box-shadow: none !important;
  border: none;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 10px;
  }
}
.btnSpecsChanger:hover,
.btnSpecsChanger:focus,
.btnSpecsChanger:active,
.btnSpecsChanger.active {
  color: $monti-white;
  font-weight: bold;
  background-color: #333333;
  outline: none !important;
  box-shadow: none !important;
}

.image-gallery-thumbnail.active {
  background-color: $monti-blue-light !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid $monti-blue-light;
}

.image-gallery-image {
  object-fit: cover;
  max-height: 500px;
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  max-height: 500px;
}

.floatWsp {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 30px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: -1px -1px 16px -3px rgba(0, 0, 0, 0.56);
  -webkit-box-shadow: -1px -1px 16px -3px rgba(0, 0, 0, 0.56);
  -moz-box-shadow: -1px -1px 16px -3px rgba(0, 0, 0, 0.56);
  z-index: 100;
}

.floatWsp img {
  width: 50px;
  height: 50px;
  margin-top: 15px;
  margin-left: 3px;
}

.noVehicles {
  font-size: 16px;
}

.linkVolver {
  font-family: "FuturaPTBold";
  font-size: 18px;
  text-decoration: none;
  color: $monti-blue-light;
  padding-right: 15px;
}

.linkVolver:hover {
  color: #222;
}

.modalContacto {
  .modal-header {
    font-family: "FuturaPTBold";
    background-color: #002C5F;
    color: white;
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }

  .titleForm {
    font-family: "FuturaPTBold";
    font-size: 14px;
    text-decoration: none;
    color: $monti-blue-light;
    text-align: center;
    padding: 15px 0 15px 0;
    margin-bottom: 0;
  }

  .formaContacto {
    color: #666;
    font-family: Arial, Helvetica, sans-serif !important;
  }
}
@media only screen and (max-width: 470px) {
  .btn-blue {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
