.vehicleCard {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 25px;

  h2 {
    font-family: "FuturaPTBold";
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    display: flex;
    color: #333;
    text-transform: uppercase;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $monti-black;
    border-radius: 5px;
    padding: 0px;
    margin: 0;
    text-align: start;
    text-transform: uppercase;
  }
}

.infoVehicle {
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #8c8c8c;
    margin: 0 0;
  }

  h4 {
    font-family: "FuturaPTBold";
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: $monti-blue;
    margin: 0 0;
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: $monti-black;
    background: #e5e5e5;
    border-radius: 5px;
    padding: 3px;
    margin: 0 0 10px 0;
    text-align: center;
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    margin: 0 0 10px 0;
  }
}

.ampliarVehiculo {
  padding: 0;
  h1 {
    font-family: "FuturaPTBold";
    font-style: normal;
    text-transform: capitalize;
    font-size: 38px;
    padding: 20px 0 20px 0;
  }
  h2 {
    font-family: "FuturaPTBold";
    font-style: normal;
    font-size: 25px;
    padding: 20px 0 20px 0;
  }
  .ficha {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }

    h5 {
      font-family: "FuturaPTBold";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      color: #889c87;
    }
    h4 {
      font-family: "FuturaPTBold";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .fichaDerecha {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px;

    h1 {
      font-size: 30px;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
    }

    h2 {
      font-size: 40px;
      margin: 0;
      padding: 10px 0 0 0;

      font-weight: bold;
    }

    .solicitarAsesoramiento {
      font-size: 20px;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    .formVehiculo {
      position: relative;
      padding: 10px;
    }
    .msgHeader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 1);
      z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 10px;
    }
  }
}

.titDisponible {
  font-size: 24px;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 0;
}

.imgGalleryCars {
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
}

.titMainPortada {
  font-family: "FuturaPTBold";
  font-size: 48px;
  line-height: 67px;
  color: #000;

  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
}

.titFinanBancaria {
  font-family: "FuturaPTBold";
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.titFinanBancariaCuotaPlan {
  font-family: "FordAntennaWGL-Light" !important;
  font-size: 15px;
  line-height: 22px;
  color: #666;
}

.titFinanBancariaPlanOvalo {
  font-family: "FuturaPTBold" !important;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.newTitPlanName {
  font-family: "FuturaPTBold" !important;
  font-size: 16px;
  line-height: 22px;
  color: $monti-black !important;
  text-transform: uppercase;
}

.newDescPlan {
  font-family: "FuturaPTBold" !important;
  font-size: 12px;
  line-height: 16px;
  color: #999 !important;
}

.titDatosValorCuota {
  font-family: "FuturaPTBold";
  font-size: 24px;
  line-height: 34px;
  color: #2d96cd;
}

.subTitInfoConvencional {
  font-family: "FuturaPTBold" !important;
  font-weight: normal !important;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.pictureCar {
  height: 230px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.titulo-categorias-home {
  font-family: "HyundaiSansHead-Bold";
  text-transform: uppercase;
  color: #D9D9D9;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  border-bottom: solid 4px #D9D9D9;
  padding-bottom: 8px;
  margin-bottom: 30px;
}
