.header {
  box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.06);
  padding: 10px 0 30px 0;
  background-color: #fff;

  .link-header {
    font-family: "HyundaiSansHead-Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #002C5F;
    text-decoration: none;
    background: transparent !important;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none;
  }

  .link-header:hover {
    color: #002C5C;
    text-decoration: none;
    background: transparent !important;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none;
  }
}
.row-header {
  padding: 0 5%;
  @media only screen and (max-width: 470px) {
    padding: 0;
  }
}